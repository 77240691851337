import classNames from "classnames";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon, Icons } from "@/components/Icon";
import { usePlayerMeta } from "@/components/player/hooks/usePlayerMeta";
import { Transition } from "@/components/utils/Transition";
import { PlayerMeta } from "@/stores/player/slices/source";
import { usePlayerStore } from "@/stores/player/store";

function shouldShowNextEpisodeButton(
  time: number,
  duration: number
): "always" | "hover" | "none" {
  const percentage = time / duration;
  const secondsFromEnd = duration - time;
  if (secondsFromEnd <= 30) return "always";
  if (percentage >= 0.9) return "hover";
  return "none";
}

const Button = memo(function Button(props: {
  className: string;
  onClick?: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  onMouseOver?: () => void;
  reference?: React.RefObject<HTMLButtonElement>;
}) {
  return (
    <button
      ref={props.reference}
      className={classNames(
        "font-bold rounded h-10 w-40 scale-95 hover:scale-100 transition-all duration-200",
        props.className
      )}
      type="button"
      onClick={props.onClick}
      style={props.style}
      onMouseOver={props.onMouseOver}
    >
      {props.children}
    </button>
  );
});

const NextEpisodeButton = memo(function NextEpisodeButton({
  timerStarted,
  timerDuration,
  loadNextEpisode,
}: {
  timerStarted: boolean;
  timerDuration: number;
  loadNextEpisode: () => void;
}) {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Note for later, put this is a useState later and put the start/cancel function usestate too
  // This way we can still interact with them outside of the useEffect
  let animation: Animation | null = null;

  function startAnimation() {
    if (!buttonRef.current) return;
    buttonRef.current.style.backgroundImage =
      "linear-gradient(to right, gray 100%, #00000000 0%)";
    // eslint-disable-next-line react-hooks/exhaustive-deps
    animation = buttonRef.current.animate([{ backgroundPositionX: "10rem" }], {
      duration: 5000,
      iterations: 1,
      easing: "linear",
      fill: "forwards",
    });
  }

  function cancelAnimation() {
    if (!animation) return;
    animation.finish();
  }

  useEffect(() => {
    if (!timerStarted) return;

    startAnimation();
    const loadNextEpisodeTimeout = setTimeout(loadNextEpisode, timerDuration);

    return () => {
      clearTimeout(loadNextEpisodeTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerStarted, loadNextEpisode, timerDuration]);

  return (
    <Button
      reference={buttonRef}
      onClick={() => loadNextEpisode()}
      onMouseOver={() => {
        timerStarted = false; // This wil clear the set timeout, but it won't re-render the component
        cancelAnimation();
      }}
      style={{
        backgroundPositionX: "0",
        backgroundRepeat: "no-repeat",
      }}
      className="bg-buttons-primary text-buttons-primaryText flex justify-center items-center next-episode-button"
    >
      <Icon className="text-xl mr-1" icon={Icons.SKIP_EPISODE} />
      {t("player.nextEpisode.next")}
    </Button>
  );
});

export function NextEpisodeButtonWrapper(props: {
  controlsShowing: boolean;
  onChange?: (meta: PlayerMeta) => void;
}) {
  const { t } = useTranslation();
  const duration = usePlayerStore((s) => s.progress.duration);
  const isHidden = usePlayerStore((s) => s.interface.hideNextEpisodeBtn);
  const meta = usePlayerStore((s) => s.meta);
  const { setDirectMeta } = usePlayerMeta();
  const hideNextEpisodeButton = usePlayerStore((s) => s.hideNextEpisodeButton);
  const metaType = usePlayerStore((s) => s.meta?.type);
  const time = usePlayerStore((s) => s.progress.time);
  const showingState = shouldShowNextEpisodeButton(time, duration);
  const status = usePlayerStore((s) => s.status);
  const setShouldStartFromBeginning = usePlayerStore(
    (s) => s.setShouldStartFromBeginning
  );

  const enableAutoSkip = true;
  const enableAutoSkipTimer = showingState === "always" && status === "playing";

  let autoSkipTimerStarted = false;

  if (enableAutoSkip && enableAutoSkipTimer) autoSkipTimerStarted = true;
  else autoSkipTimerStarted = false;

  let show = false;
  if (showingState === "always") show = true;
  else if (showingState === "hover" && props.controlsShowing) show = true;
  if (isHidden || status !== "playing" || duration === 0) show = false;

  const animation = showingState === "hover" ? "slide-up" : "fade";
  let bottom = "bottom-[calc(6rem+env(safe-area-inset-bottom))]";
  if (showingState === "always")
    bottom = props.controlsShowing
      ? bottom
      : "bottom-[calc(3rem+env(safe-area-inset-bottom))]";

  const nextEp = meta?.episodes?.find(
    (v) => v.number === (meta?.episode?.number ?? 0) + 1
  );

  const loadNextEpisode = useCallback(() => {
    if (!meta || !nextEp) return;
    const metaCopy = { ...meta };
    metaCopy.episode = nextEp;
    setShouldStartFromBeginning(true);
    setDirectMeta(metaCopy);
    props.onChange?.(metaCopy);
  }, [setDirectMeta, nextEp, meta, props, setShouldStartFromBeginning]);

  if (!meta?.episode || !nextEp) return null;
  if (metaType !== "show") return null;

  return (
    <Transition
      animation={animation}
      show={show}
      className="absolute right-[calc(3rem+env(safe-area-inset-right))] bottom-0"
    >
      <div
        className={classNames([
          "absolute bottom-0 right-0 transition-[bottom] duration-200 flex items-center space-x-3",
          bottom,
        ])}
      >
        <Button
          className="py-px box-content bg-buttons-secondary hover:bg-buttons-secondaryHover bg-opacity-90 text-buttons-secondaryText"
          onClick={hideNextEpisodeButton}
        >
          {t("player.nextEpisode.cancel")}
        </Button>
        <NextEpisodeButton
          timerStarted={autoSkipTimerStarted}
          timerDuration={5000}
          loadNextEpisode={loadNextEpisode}
        />
      </div>
    </Transition>
  );
}
